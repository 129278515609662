<script>
	import Base from '@backend/Base.vue';
	import BOGen from '../helper/BOGen';
	import Gen from '../helper/Gen';

	export default {
		name: "BoAskedDoctor",
		extends: Base,
		data() {
			return {
				Name: "BoAskedDoctor",
				hero: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			onRefreshPage() {
				this.statusValidation()
			},
			submitHero() {
				this.hero.type = 'submitHero'
				BOGen.apirest('/' + this.Name, this.hero, (err, resp) => {
					if (err) return Gen.info((err.resp.message || "please fill mandatory fields"), "danger", 3000,
						".col-info")
					Gen.info((resp.message), "success", 3000, ".col-info")
					setTimeout(() => {
						$('#collapseExample').hide()
					}, 3000);
				}, "POST");
			},
			showEdit() {
				if ($("#collapseExample").css("display") == "flex")
					$('#collapseExample').hide()
				else
					$('#collapseExample').css("display", "flex")
			},
			statusValidation() {
				if (this.row.ad_status == 'Y') {
					this.$set(this.mrValidation, 'ad_answerd_by', 'required')
					this.$set(this.mrValidation, 'ad_answer', 'required')
				} else {
					this.$set(this.mrValidation, 'ad_answerd_by', '')
					this.$set(this.mrValidation, 'ad_answer', '')
				}
			}
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'(v) {
				if (!v) return
				this.search()
			},
			'row.ad_status'() {
				this.statusValidation()
			}
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title mb-0">Hero Image</h5>
					</div>

					<VForm @resp="submitHero" method="post">
						<div class="card-body">
							<div class="alert alert-info">
								Image Requirements for Hero Image :
								<ul class="mb-0 mt-2">
									<li><strong>Dimension: </strong><span>1350x420px (Desktop), 768x1285px
											(Mobile)</span></li>
									<li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
									<li><strong>Max. Size: </strong><span>2 MB</span></li>
								</ul>
							</div>
							<div class="row">
								<div class="col-md-8">
									<div class="wrap_slider_img">
										<img :src="uploader(hero.ash_img,'850')" class="img-responsive" />
										<div class="slider_name">
											<p>{{hero.ash_title}}</p>
										</div>
										<a href="javascript:;" @click="showEdit" class="bullet_edit"><i
												class="ti-marker-alt"></i></a>
									</div>
								</div>
							</div>
							<div class="row collapse mt-4" id="collapseExample">
								<div class="col-info col-12"></div>
								<div class="col-sm-3 mb-3">
									<BoField name="ash_mobile_img">
										<Uploader v-bind="validation('ash_mobile_img')" :attr="{required:1}"
											:param="{thumbnail:true,img_ratio:768/1285,rule_size:[768,1285]}"
											name="ash_mobile_img" v-model="hero.ash_mobile_img" type="hero_mobile"
											uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-sm-9">
									<BoField name="ash_img">
										<Uploader v-bind="validation('ash_img')" :attr="{required:1}"
											:param="{thumbnail:true,img_ratio:1350/418,rule_size:[1350,418]}"
											name="ash_img" v-model="hero.ash_img" type="hero_desktop"
											uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-4 col-md-3 mt-3">
									<button type="submit"
										class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
								</div>
							</div>
						</div>
					</VForm>
				</div>
			</div>
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-sm-3">
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search"
												class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="ad_subject"></SortField>
									</th>
									<th>{{fields.ad_status}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.ad_subject}}</td>
									<td>
										<label v-if="v.ad_status=='Y'"
											class="label label-success">Answered</label>&nbsp;
										<label v-else class="label label-danger">Not Answered</label>&nbsp;
										<label v-if="v.ad_publish=='Y'" class="label label-info">Publish</label>&nbsp;
										<label v-else class="label label-warning">Unpublish</label>&nbsp;
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a href="javascript:;" class="icon_action" @click="changeStatus(k)"
											v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
											v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="ad_fullname" :attr="{readonly:'readonly'}" v-model="row.ad_fullname">
							</BoField>
							<BoField name="ad_email" :attr="{readonly:'readonly',type:'email'}" v-model="row.ad_email">
							</BoField>
							<BoField name="ad_subject" :attr="{readonly:'readonly'}" v-model="row.ad_subject"></BoField>
							<!-- <BoField name="ad_phone" v-model="row.ad_phone"></BoField> -->
							<BoField name="ad_answerd_by" :attr="{readonly:'readonly'}" v-model="row.ad_answerd_by">
							</BoField>
							<BoField name="ad_tags">
								<TagsInput name="ad_tags" style="display:block" v-model="row.ad_tags"
									:attr="validation('ad_tags')"></TagsInput>
							</BoField>
							<!-- <div class="row">	
								<div class="col-7">
									<BoField name="ad_status">
										<div class="row">
											<radio name="ad_status" v-model="row.ad_status" option="D" :attr="validation('ad_status')">Default</radio>
											<radio name="ad_status" v-model="row.ad_status" option="Y" >Answered</radio>
											<radio name="ad_status" v-model="row.ad_status" option="N">No</radio>
										</div>
									</BoField>
								</div>
							</div> -->
							<div class="row">
								<div class="col-7">
									<BoField name="ad_publish">
										<div class="row">
											<radio name="ad_publish" v-model="row.ad_publish" option="Y">Published
											</radio>
											<radio name="ad_publish" v-model="row.ad_publish" option="N">Unpublished
											</radio>
										</div>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-sm-7">
							<BoField name="ad_question" mandatory>
								<p>{{row.ad_question}}</p>
							</BoField>
							<BoField name="ad_answer" mandatory>
								<div v-html="row.ad_answer"></div>
							</BoField>
							<!-- <BoField name="ad_answer" mandatory>
	                          <CKEditor name="ad_answer" v-model="row.ad_answer" v-bind="validation('ad_answer')"></CKEditor>
	                        </BoField> -->
						</div>
						<div class="col-12 text-right">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit
									Form<i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>